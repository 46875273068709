import React, { useEffect, useState } from 'react';
import '~css/global.css';
import { useScrollToTop } from './hooks/use-scroll-to-top.tsx';
import Router from '~routes/routes.tsx';
import ThemeProvider from './components/theme';
import { fetchCart } from '~redux/slices/shopping/cartSlice';
import { useDispatch } from 'react-redux';
import { checkAndSaveImages } from '~components/utils/imageStorage.jsx';
import PasswordPrompt from './PasswordPrompt.tsx';

const App: React.FC = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        // Kiểm tra mật khẩu từ localStorage khi ứng dụng khởi động
        return localStorage.getItem('isAuthenticated') === 'true';
    });
    const dispatch = useDispatch();

    useEffect(() => {
        checkAndSaveImages();
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            //@ts-ignore
            dispatch(fetchCart());
        }
    }, [dispatch, isAuthenticated]);

    useScrollToTop();

    const handlePasswordSubmit = (password: string) => {
        if (password === '1993@') {
            localStorage.setItem('isAuthenticated', 'true');
            setIsAuthenticated(true);
        } else {
            alert('Sai mật khẩu');
        }
    };

    if (!isAuthenticated) {
        return <PasswordPrompt onPasswordSubmit={handlePasswordSubmit} />;
    }

    return (
        <ThemeProvider>
            <div className="App">
                <Router />
            </div>
        </ThemeProvider>
    );
};

export default App;




// import React, { useEffect } from 'react';
// import '~css/global.css';
// import { useScrollToTop } from './hooks/use-scroll-to-top.tsx';
// import Router from '~routes/routes.tsx';
// import ThemeProvider from './components/theme';
// import { fetchCart } from '~redux/slices/shopping/cartSlice';
// import { useDispatch } from 'react-redux';
// import { checkAndSaveImages } from '~components/utils/imageStorage.jsx';

// const App: React.FC = () => {
//     const dispatch = useDispatch();

//     useEffect(() => {
//         checkAndSaveImages();
//     }, []);

//     useEffect(() => {
//         //@ts-ignore
//         dispatch(fetchCart());
//     }, [dispatch]);

//     useScrollToTop();

//     return (
//         <ThemeProvider>
//             <div className="App">
//                 <Router />
//             </div>
//         </ThemeProvider>
//     );
// };

// export default App;
