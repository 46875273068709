import { encryptData, decryptData } from "~hooks/auth/cryptoHelpers";

export const getAccessToken = () => {
    try {
        const encryptedToken = localStorage.getItem('ConfigToken') || sessionStorage.getItem('ConfigToken') || null;
        const decryptedToken = encryptedToken ? decryptData(encryptedToken) : null;
        return decryptedToken ?? "";
    } catch (error) {
        console.error("Decryption error:", error);
        // Clear corrupted token
        localStorage.removeItem('ConfigToken');
        sessionStorage.removeItem('ConfigToken');
        // Redirect to login or handle error
        window.location.href = '/';
        return null;
    }
};


export const setNewToken = (new_token) => {
    const isRememberMe = localStorage.getItem('rememberMe') === 'true';
    const encryptedToken = encryptData(new_token);
    if (isRememberMe) {
        localStorage.setItem('ConfigToken', encryptedToken);
    } else {
        sessionStorage.setItem('ConfigToken', encryptedToken);
    }
    return encryptedToken;
};


export const getUserName = () => {
    try {
        const encryptedData = localStorage.getItem('ConfigUser') || sessionStorage.getItem('ConfigUser');
        if (!encryptedData) return null;
        const decryptedData = decryptData(encryptedData);
        return decryptedData?.userName;
    } catch (error) {
        console.error("Decryption error:", error);
        localStorage.removeItem('ConfigUser');
        sessionStorage.removeItem('ConfigUser');
        return null;
    }
};

export const getUserId = () => {
    try {
        const encryptedData = localStorage.getItem('ConfigUser') || sessionStorage.getItem('ConfigUser');
        if (!encryptedData) return null;
        const decryptedData = decryptData(encryptedData);
        return decryptedData?.userId;
    } catch (error) {
        console.error("Decryption error:", error);
        localStorage.removeItem('ConfigUser');
        sessionStorage.removeItem('ConfigUser');
        return null;
    }
};

export const getRole = () => {
    try {
        const encryptedData = localStorage.getItem('ConfigUser') || sessionStorage.getItem('ConfigUser');
        if (!encryptedData) return null;
        const decryptedData = decryptData(encryptedData);
        return decryptedData?.role;
    } catch (error) {
        console.error("Decryption error:", error);
        localStorage.removeItem('ConfigUser');
        sessionStorage.removeItem('ConfigUser');
        return null;
    }
};


export const getUserEmail = () => {
    try {
        const encryptedData = localStorage.getItem('ConfigUser') || sessionStorage.getItem('ConfigUser');
        if (!encryptedData) return null;
        const decryptedData = decryptData(encryptedData);
        return decryptedData?.email;
    } catch (error) {
        console.error("Decryption error:", error);
        localStorage.removeItem('ConfigUser');
        sessionStorage.removeItem('ConfigUser');
        return null;
    }
};

export const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });






// import { encryptData, decryptData } from "~hooks/auth/cryptoHelpers";

// export const getAccessToken = () => {
//     try {
//         const encryptedToken = localStorage.getItem('ConfigToken') || sessionStorage.getItem('ConfigToken') || null;
//         const decryptedToken = encryptedToken ? decryptData(encryptedToken) : null;
//         return decryptedToken ?? "";
//     } catch (error) {
//         console.error("Decryption error:", error);
//         // Clear corrupted token
//         localStorage.removeItem('ConfigToken');
//         sessionStorage.removeItem('ConfigToken');
//         // Redirect to login or handle error
//         window.location.href = '/';
//         return null;
//     }
// };


// export const setNewToken = (new_token) => {
//     const isRememberMe = localStorage.getItem('rememberMe') === 'true';
//     const encryptedToken = encryptData(new_token);
//     if (isRememberMe) {
//         localStorage.setItem('ConfigToken', encryptedToken);
//     } else {
//         sessionStorage.setItem('ConfigToken', encryptedToken);
//     }
//     return encryptedToken;
// };


// export const getUserName = () => {
//     try {
//         const encryptedData = localStorage.getItem('ConfigUser') || sessionStorage.getItem('ConfigUser');
//         if (!encryptedData) return null;
//         const decryptedData = decryptData(encryptedData);
//         return decryptedData?.userName;
//     } catch (error) {
//         console.error("Decryption error:", error);
//         localStorage.removeItem('ConfigUser');
//         sessionStorage.removeItem('ConfigUser');
//         return null;
//     }
// };

// export const getUserId = () => {
//     try {
//         const encryptedData = localStorage.getItem('ConfigUser') || sessionStorage.getItem('ConfigUser');
//         if (!encryptedData) return null;
//         const decryptedData = decryptData(encryptedData);
//         return decryptedData?.userId;
//     } catch (error) {
//         console.error("Decryption error:", error);
//         localStorage.removeItem('ConfigUser');
//         sessionStorage.removeItem('ConfigUser');
//         return null;
//     }
// };

// export const getRole = () => {
//     try {
//         const encryptedData = localStorage.getItem('ConfigUser') || sessionStorage.getItem('ConfigUser');
//         if (!encryptedData) return null;
//         const decryptedData = decryptData(encryptedData);
//         return decryptedData?.role;
//     } catch (error) {
//         console.error("Decryption error:", error);
//         localStorage.removeItem('ConfigUser');
//         sessionStorage.removeItem('ConfigUser');
//         return null;
//     }
// };


// export const getUserEmail = () => {
//     try {
//         const encryptedData = localStorage.getItem('ConfigUser') || sessionStorage.getItem('ConfigUser');
//         if (!encryptedData) return null;
//         const decryptedData = decryptData(encryptedData);
//         return decryptedData?.email;
//     } catch (error) {
//         console.error("Decryption error:", error);
//         localStorage.removeItem('ConfigUser');
//         sessionStorage.removeItem('ConfigUser');
//         return null;
//     }
// };

// export const getBase64 = (file) =>
//     new Promise((resolve, reject) => {
//         const reader = new FileReader();
//         reader.readAsDataURL(file);
//         reader.onload = () => resolve(reader.result);
//         reader.onerror = (error) => reject(error);
//     });