import { get, set } from 'idb-keyval';

// Danh sách các ảnh
const listLocalImage = {
    // Title
    LogoTitile: `${process.env.PUBLIC_URL}/logo23.png`,
    MainLogo: `${process.env.PUBLIC_URL}/logo21.png`,

    // List Big Image
    About: `https://res.cloudinary.com/cd0110/image/upload/v1718279162/Main_Image/dm0sxskylxj5wbtgc58f.jpg`,
    LikedBlog: `https://res.cloudinary.com/cd0110/image/upload/v1718279161/Main_Image/gbxc7m8bejacnm2fpquq.webp`,
    LikedProduct: `https://res.cloudinary.com/cd0110/image/upload/v1718279162/Main_Image/m6xkmdgmztm8vn1fc2k3.webp`,
    Recommended: `https://res.cloudinary.com/cd0110/image/upload/v1718279161/Main_Image/es8jkklekokhlplk4xq9.png`,
    SenEmail: `https://res.cloudinary.com/cd0110/image/upload/v1718279162/Main_Image/b5uikbm7e4dgdaeqjy3j.gif`,
};

// Kiểm tra và lưu ảnh nếu cần
const checkAndSaveImages = async () => {
    const imageKeys = Object.keys(listLocalImage);

    for (const key of imageKeys) {
        const storedImage = await get(key);
        if (!storedImage) {
            await saveImageToIndexedDB(key, listLocalImage[key]);
        }
    }
};

// Lưu một ảnh vào IndexedDB
const saveImageToIndexedDB = async (key, url) => {
    try {
        const response = await fetch(url);
        if (!response.ok) {
            console.error(`Failed to fetch image ${url}: ${response.statusText}`);
            return;
        }

        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = async function () {
            const base64String = reader.result;
            await set(key, base64String);
        };
        reader.readAsDataURL(blob);
    } catch (error) {
        console.error(`Failed to save image ${url} to IndexedDB: ${error}`);
    }
};

export { saveImageToIndexedDB, checkAndSaveImages };
