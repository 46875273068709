/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useResponsive } from '~hooks/use-responsive.tsx';
import { Box, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import FacebookLogo from '~components/common/share-icon/Facebook_Icon';
import TiktokLogo from '~components/common/share-icon/Titok_Icon';
import ShoppeeLogo from '~components/common/share-icon/Shoppee_Icon';
import ZaloIcon from '~components/common/share-icon/Zalo_Icon';


const currentYear = new Date().getFullYear();



const Footer = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    // @ts-ignore
    const lgUp = useResponsive('up', 'lg');

    return (
        <Box
        >
            <div style={{
                background: "#f7deea",
                padding: "30px 0",
                width: "100%",
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: "column",
                borderTop: '1px solid #DDDDDD'
            }}>
                <Grid container spacing={0.5} sx={{ display: "flex", flexDirection: "row" }}>
                    <Grid item xs={12} md={6} style={{ marginLeft: "15px" }}>
                        <Typography variant="h6" gutterBottom
                            onClick={() => navigate('/about')}
                            sx={{
                                fontWeight: '500',
                                color: 'black',
                                cursor: 'pointer',
                                '&:hover': {
                                    color: '#0584CA',
                                }
                            }}
                        >Về Teespresso</Typography>
                        <a href="https://maps.app.goo.gl/xBy9ZBEnLZeRySYr5" target="_blank" rel="noopener noreferrer" style={{ color: 'black', textDecoration: 'none' }}>
                            <Typography variant="body2"
                                sx={{
                                    color: 'black',
                                    cursor: 'pointer',
                                    '&:hover': {
                                        color: '#0584CA',
                                    }
                                }}>
                                Địa chỉ: 112A Tây Thạnh, Phường Tây Thạnh, Quận Tân Phú, tp.Hồ Chí Minh
                            </Typography>
                        </a>
                        <Typography variant="body2"
                            sx={{
                                color: 'black',
                                cursor: 'pointer',
                                '&:hover': {
                                    color: '#0584CA',
                                }
                            }}>
                            SĐT: 0931877697
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 0, margin: 0, padding: 0 }}>
                            <FacebookLogo url="https://www.facebook.com/profile.php?id=61561259465952" />
                            <TiktokLogo url="https://www.tiktok.com/@trsuatinhthuongnvvuitinh" />
                            <ShoppeeLogo url="https://shopeefood.vn/ho-chi-minh/teespresso-tra-thom-chuan-vi-112a-tay-thanh" />
                            <ZaloIcon url="https://zalo.me/0931877697" />
                        </Box>

                        <Typography variant="body2" textAlign="left" style={{ fontWeight: '500', color: 'black' }} >
                            &copy; {currentYear} <span>Teespresso</span>
                        </Typography>
                    </Grid>

                    <Grid item xs={5} md={3} sx={{ marginLeft: "15px", marginTop: "15px" }}>
                        <Typography variant="h6" gutterBottom style={{ fontWeight: '500', color: 'black' }}>Về các chính sách</Typography>
                        <Typography variant="body2"
                            sx={{
                                color: 'black',
                                cursor: 'pointer',
                                '&:hover': {
                                    color: '#0584CA',
                                }
                            }}
                            onClick={() => navigate('/security_police')}>
                            Chính sách bảo mật
                        </Typography>
                        <Typography variant="body2"
                            sx={{
                                color: 'black',
                                cursor: 'pointer',
                                '&:hover': {
                                    color: '#0584CA',
                                }
                            }}
                            onClick={() => navigate('/shipping_police')}>
                            Chính sách giao vận
                        </Typography>
                        <Typography variant="body2"
                            sx={{
                                color: 'black',
                                cursor: 'pointer',
                                '&:hover': {
                                    color: '#0584CA',
                                }
                            }}
                            onClick={() => navigate('/cancel_police')}>
                            Chính sách hoàn hủy
                        </Typography>

                    </Grid>

                    <Grid item xs={0} md={0.1}>
                        <p style={{ color: 'black', fontWeight: '500' }}></p>
                    </Grid>

                    <Grid item xs={5} md={2} sx={{ marginLeft: "15px", marginTop: "15px" }}>
                        <Typography variant="h6" gutterBottom
                            style={{ fontWeight: '500', color: 'black' }}
                        // sx={{ marginLeft: { xs: 40, md: 0 } }}
                        >Đối tác và liên hệ</Typography>
                        <Typography variant="body2"
                            sx={{
                                // marginLeft: { xs: 40, md: 0 },
                                color: 'black',
                                cursor: 'pointer',
                                '&:hover': {
                                    color: '#0584CA',
                                }
                            }}
                            onClick={() => navigate('/contact')}>
                            Đối tác liên hệ và góp ý
                        </Typography>
                        <Typography variant="body2"
                            sx={{
                                color: 'black',
                                cursor: 'pointer',
                                '&:hover': {
                                    color: '#0584CA',
                                }
                            }}
                            onClick={() => navigate('/how_to_pay')}>
                            Hướng dẫn thanh toán VNPay
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 1, marginLeft: { md: 0 } }}>
                            <img
                                height="40px"
                                width="130px"
                                src="https://res.cloudinary.com/cd0110/image/upload/v1718794349/Main_Image/idfpargqltiamj1eslrl.png" alt="nostones.vn"
                                onClick={() => window.open('https://nostones.vn')}
                            />
                        </Box>
                    </Grid>
                </Grid>

            </div>
        </Box>
    );
}

export default Footer;
