import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import commonReducer from '../slices/common/commonSlice.tsx';
import cartReducer from '../slices/shopping/cartSlice.tsx';
import imageUpdateReducer from '../slices/common/image_updateSlice.tsx';
import checkOutReducer from '../slices/shopping/checkOutSlice.tsx';
import productsSliceReducer from '../slices/shopping/productsSlice.jsx';
import categoryReducer from '../slices/shopping/categorySlice.jsx';
import brandReducer from '../slices/shopping/brandSlice.jsx';
import shippingReducer from '../slices/shopping/shippingSlice.jsx';
import paymentReducer from '../slices/shopping/paymentSlice.jsx';
// import supplierReducer from '../slices/shopping/supplierSlice.jsx';
// import inventoryReducer from '../slices/shopping/inventorySlice.jsx';
import campaignReducer from '../slices/shopping/campaignSlice.jsx';
import cuponReducer from '../slices/shopping/cuponSlice.jsx';
import promotionReducer from '../slices/shopping/promotionSlice.jsx';
import detail_inventoryReducer from '../slices/shopping/detail_inventorySlice.jsx';
import orderReducer from '../slices/shopping/orderSlice.jsx';

import authReducer from '~redux/slices/auth/authSlice.tsx';
// import checkBoxReducer from '../slices/common/TEST/checkBoxSlice.jsx';
// import modalCloseReducer from '../slices/common/TEST/modalCloseSlice.jsx';
// import chartFilterDonHangSliceReducer from "../slices/common/TEST/chartFilterDonHangSlice.jsx";
// import chartFilterDoanhThuSliceReducer from "../slices/common/TEST/chartFilterDoanhThuSlice.jsx";
// import chartFilterNguoiDungMoiSliceReducer from "../slices/common/TEST/chartFilterNguoiDungMoiSlice.jsx";


const rootReducer = {
    auth: authReducer,
    cart: cartReducer,
    product: productsSliceReducer,
    category: categoryReducer,
    brand: brandReducer,
    shipping: shippingReducer,
    payment: paymentReducer,
    // supplier: supplierReducer,
    // inventory: inventoryReducer,
    campaign: campaignReducer,
    cupon: cuponReducer,
    promotion: promotionReducer,
    detail_inventory: detail_inventoryReducer,
    order: orderReducer,



    checkOutInfo: checkOutReducer,
    common: commonReducer,
    image: imageUpdateReducer,

    // tableCheckBox: checkBoxReducer,
    // modalClose: modalCloseReducer,
    // chartFilterDonHang: chartFilterDonHangSliceReducer,
    // chartFilterDoanhThu: chartFilterDoanhThuSliceReducer,
    // chartFilterNguoiDungMoi: chartFilterNguoiDungMoiSliceReducer,

};

const store = configureStore({
    reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;
