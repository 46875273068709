import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CartAPI from "~services/shoppingService/cart_api";
import { getUserId } from '~utils/helper';

export interface CartItem {
    anhMinhHoa: string;
    tenSanPham: string;
    donGia: number;
    maSanPham: number;
    soLuong: number;
    note: string;
    isMuaSau: boolean;
    soLuongConLai: number;
}

export interface CartState {
    isCartOpen: boolean;
    items: CartItem[];
}

const isUserLoggedIn = () => {
    return getUserId() !== null;
};

// Đồng bộ giỏ hàng khi người dùng đăng nhập
export const syncCartOnLogin = createAsyncThunk(
    'cart/syncCartOnLogin',
    async (_, { getState, dispatch }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { cart } = getState() as { cart: CartState };
        if (isUserLoggedIn()) {
            try {
                const maTaiKhoan = getUserId();
                // Lấy giỏ hàng từ server
                const response = await CartAPI.fetchCart(maTaiKhoan);
                const serverCartItems = response.data;

                // console.log("serverCartItems", serverCartItems);

                // Lấy giỏ hàng từ localStorage
                const localStorageCart = localStorage.getItem('cart');
                let localCartItems = [];
                if (localStorageCart) {
                    localCartItems = JSON.parse(localStorageCart);
                }

                // console.log("localCartItems", localCartItems);

                // Gộp giỏ hàng
                const mergedCart = mergeCarts(localCartItems, serverCartItems);

                // console.log("mergedCart", mergedCart);

                // Cập nhật giỏ hàng trên server với giỏ hàng gộp
                const createCartPromises = mergedCart.map(item => {
                    const body = {
                        maTaiKhoan: getUserId(),
                        maSanPham: item.maSanPham,
                        donGia: item.donGia,
                        soLuong: item.soLuong,
                        anhMinhHoa: item.anhMinhHoa === "" ? `${process.env.REACT_APP_DEFAULT_IMAGE}` : item.anhMinhHoa,
                        thanhTien: item.donGia * item.soLuong,
                        note: item.note,
                    };
                    return CartAPI.createCart(body);
                });

                await Promise.all(createCartPromises);


                // Gửi yêu cầu lấy lại dữ liệu giỏ hàng mới nhất
                dispatch(fetchCart());

                // Nếu người dùng đã đăng nhập thì xóa giỏ hàng trong localStorage
                localStorage.removeItem('cart');
                return mergedCart;
            } catch (error) {
                console.error("Lỗi đồng bộ giỏ hàng khi đăng nhập", error);
            }
        } else {
            // Nếu người dùng đăng xuất, giữ lại giỏ hàng trong localStorage
            const maTaiKhoan = getUserId();
            // Lấy giỏ hàng từ server
            const response = await CartAPI.fetchCart(maTaiKhoan);
            const serverCartItems = response.data;
            localStorage.setItem('cart', JSON.stringify(serverCartItems));
            return serverCartItems;
        }
    }
);

// Hàm gộp giỏ hàng
function mergeCarts(localCartItems: CartItem[], serverCartItems: CartItem[]) {
    const mergedCart = [...serverCartItems];

    localCartItems.forEach(localItem => {
        const existingItemIndex = mergedCart.findIndex(serverItem => serverItem.maSanPham === localItem.maSanPham);
        if (existingItemIndex > -1) {
            // Nếu mặt hàng tồn tại, cộng dồn số lượng
            // mergedCart[existingItemIndex].soLuong += localItem.soLuong;
            if (localItem.soLuong > mergedCart[existingItemIndex].soLuong) {
                mergedCart[existingItemIndex].soLuong = localItem.soLuong;
            }
        } else {
            // Nếu mặt hàng không tồn tại, thêm vào giỏ hàng gộp
            mergedCart.push(localItem);
        }
    });

    return mergedCart;
}



// Đồng bộ giỏ hàng khi người dùng đăng xuất
export const syncCartOnLogOut = createAsyncThunk(
    'cart/syncOnLogOut',
    async (_, { rejectWithValue }) => {
        try {
            const maTaiKhoan = getUserId();
            // Lấy giỏ hàng từ server
            const response = await CartAPI.fetchCart(maTaiKhoan);
            const serverCartItems = response.data;
            localStorage.setItem('cart', JSON.stringify(serverCartItems));
            return serverCartItems;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);


// Lấy danh sách giỏ hàng
export const fetchCart = createAsyncThunk(
    'cart/fetchCart', async () => {
        if (isUserLoggedIn()) {
            const maTaiKhoan = getUserId();
            const response = await CartAPI.fetchCart(maTaiKhoan);
            // localStorage.setItem('cart', JSON.stringify(response.data)); // Cập nhật localStorage với mảng các đối tượng
            return response.data;
        } else {
            const localStorageCart = localStorage.getItem('cart');
            return localStorageCart ? JSON.parse(localStorageCart) : [];
        }
    });




// Thêm mặt hàng vào giỏ hàng
export const addToCart = createAsyncThunk(
    'cart/addToCart',
    async (item: CartItem, { getState, rejectWithValue }) => {
        try {
            if (isUserLoggedIn()) {
                const { cart } = getState() as { cart: CartState };
                let newItems = [];
                const maTaiKhoan = getUserId();
                const cartFromAPI = await CartAPI.fetchCart(maTaiKhoan);
                //@ts-ignore
                // Chỉ kiểm tra note để xác định sản phẩm có giống nhau hay không
                const existingItem = cart.items.find(x =>
                    x.maSanPham === item.maSanPham &&
                    JSON.stringify(x.note) === JSON.stringify(item.note) // So sánh note
                );

                if (existingItem) {
                    //@ts-ignore
                    newItems = cartFromAPI.data.map(x =>
                        x.maSanPham === item.maSanPham &&
                            JSON.stringify(x.note) === JSON.stringify(item.note) ? {
                            ...x,
                            soLuong: x.soLuong + 1,
                            donGia: (x.soLuong + 1) * x.donGia,
                            isMuaSau: x.isMuaSau,
                        } : x
                    );
                    const maTaiKhoan = getUserId();
                    await CartAPI.updateCart({ maTaiKhoan, items: newItems });
                } else {
                    // newItems = [...cart.items, item];
                    newItems = [...cart.items, {
                        ...item,
                        soLuong: item.soLuong,
                        soLuongConLai: 100,
                        isMuaSau: false,
                    }];

                    const body = {
                        maTaiKhoan: getUserId(),
                        maSanPham: item.maSanPham,
                        donGia: item.donGia,
                        soLuong: item.soLuong,
                        anhMinhHoa: item.anhMinhHoa,
                        thanhTien: item.donGia * item.soLuong,
                        note: item.note,
                    }
                    await CartAPI.createCart(body);
                }
                return newItems;
            } else {
                const cart = JSON.parse(localStorage.getItem('cart') || '[]');
                //@ts-ignore
                const existingItem = cart.find(x => x.maSanPham === item.maSanPham);
                let newItems = [];

                if (existingItem) {
                    //@ts-ignore
                    newItems = cart.map(x =>
                        x.maSanPham === item.maSanPham ? {
                            ...x,
                            soLuong: x.soLuong + 1,
                            thanhTien: (x.soLuong + 1) * x.donGia,
                            anhMinhHoa: x.anhMinhHoa || `${process.env.REACT_APP_DEFAULT_IMAGE}`,
                            soLuongConLai: 100,
                            isMuaSau: x.isMuaSau,
                        } : x
                    );
                } else {
                    newItems = [...cart, {
                        ...item,
                        soLuong: 1,
                        anhMinhHoa: item.anhMinhHoa || `${process.env.REACT_APP_DEFAULT_IMAGE}`,
                        thanhTien: item.donGia * item.soLuong,
                        soLuongConLai: 100,
                        isMuaSau: false,
                    }];

                    // console.log("newItems", newItems);
                }

                localStorage.setItem('cart', JSON.stringify(newItems));
                return newItems;
            }
        } catch (error) {
            return rejectWithValue('Unable to add to cart');
        }
    }
);


// Xóa mặt hàng khỏi giỏ hàng
export const removeFromCart = createAsyncThunk(
    'cart/removeFromCart',
    async (maSanPham: number, { getState, rejectWithValue }) => {
        const { cart } = getState() as { cart: CartState };
        const newItems = cart.items.filter(item => item.maSanPham !== maSanPham);

        try {
            if (isUserLoggedIn()) {
                const maTaiKhoan = getUserId();
                await CartAPI.deleteCartItem(maTaiKhoan, maSanPham);
            } else {
                localStorage.setItem('cart', JSON.stringify(newItems));
            }
            return newItems;
        } catch (error) {
            return rejectWithValue('Unable to remove from cart');
        }
    }
);





// Chỉnh sửa số lượng mặt hàng trong giỏ hàng
export const editQuantity = createAsyncThunk(
    'cart/editQuantity',
    async ({ maSanPham, soLuong }: { maSanPham: number, soLuong: number }, { getState, rejectWithValue }) => {
        const { cart } = getState() as { cart: CartState };
        const updatedItems = cart.items.map(item =>
            item.maSanPham === maSanPham ? { ...item, soLuong } : item
        );

        try {
            if (isUserLoggedIn()) {
                const maTaiKhoan = getUserId();
                await CartAPI.updateCart({ maTaiKhoan, items: updatedItems });
            } else {
                localStorage.setItem('cart', JSON.stringify(updatedItems));
            }
            return updatedItems;
        } catch (error) {
            return rejectWithValue('Unable to edit item quantity');
        }
    }
);



// Chuyển mặt hàng vào mua sau
export const moveProductToBuyLater = createAsyncThunk(
    'cart/moveProductToBuyLater',
    async (maSanPham: number, { getState, rejectWithValue }) => {
        const { cart } = getState() as { cart: CartState };
        const newItems = cart.items.filter(item => item.maSanPham !== maSanPham);

        try {
            if (isUserLoggedIn()) {
                const maTaiKhoan = getUserId();
                const data = {
                    maSanPham: maSanPham,
                    maTaiKhoan: maTaiKhoan,
                }

                await CartAPI.moveToBuyLater(data);
            } else {
                localStorage.setItem('cart', JSON.stringify(newItems));
            }
            return newItems;
        } catch (error) {
            return rejectWithValue('Unable to move item to buy later');
        }
    }
);


// Chuyển mặt hàng từ mua sau về giỏ hàng
export const moveProductToCart = createAsyncThunk(
    'cart/moveProductToCart',
    async (maSanPham: number, { getState, rejectWithValue }) => {
        const { cart } = getState() as { cart: CartState };
        const newItems = cart.items.filter(item => item.maSanPham !== maSanPham);

        try {
            if (isUserLoggedIn()) {
                const maTaiKhoan = getUserId();
                const data = {
                    maSanPham: maSanPham,
                    maTaiKhoan: maTaiKhoan,
                }

                await CartAPI.moveToCart(data);
            } else {
                localStorage.setItem('cart', JSON.stringify(newItems));
            }
            return newItems;
        } catch (error) {
            return rejectWithValue('Unable to move item to cart');
        }
    }
);





// Slice
const initialState: CartState = {
    isCartOpen: false,
    items: []
};

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        toggleCartPopup: (state) => {
            state.isCartOpen = !state.isCartOpen;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCart.fulfilled, (state, action) => {
                state.items = action.payload;
            })
            .addCase(addToCart.fulfilled, (state, action) => {
                state.items = action.payload;
            })
            .addCase(removeFromCart.fulfilled, (state, action) => {
                state.items = action.payload;
            })
            .addCase(editQuantity.fulfilled, (state, action) => {
                state.items = action.payload;
            })
            .addCase(syncCartOnLogin.fulfilled, (state, action) => {
                state.items = action.payload;
            })
            .addCase(syncCartOnLogOut.fulfilled, (state, action) => {
                state.items = action.payload;
            })
            .addCase(moveProductToBuyLater.fulfilled, (state, action) => {
                state.items = action.payload;
            })
            .addCase(moveProductToCart.fulfilled, (state, action) => {
                state.items = action.payload;
            })

            ;
    }
});

export const { toggleCartPopup } = cartSlice.actions;
export default cartSlice.reducer;
