import React from "react";
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { useResponsive } from '~hooks/use-responsive.tsx';
import { bgBlur } from '~components/theme/css.tsx';
import Iconify from '~components/image/iconify/iconify.tsx';
import { NAV, HEADER } from '../component/config-layout.tsx';
import AccountPopover from '../component/account-popover.tsx';
import CartPopover from '../component/cart-popover.tsx';
import Container from '@mui/material/Container';
import { useNavigate } from 'react-router-dom';


//--------------------------Header--------------------------//
// @ts-ignore
const Header = ({ onOpenNav, menuControl }) => {
    const theme = useTheme();
    const navigate = useNavigate();

    // @ts-ignore
    const lgUp = useResponsive('up', 'up');

    const renderContent = (
        <>
            {!lgUp && (
                <IconButton onClick={onOpenNav} sx={{}}>
                    <
                        // @ts-ignore
                        Iconify icon="eva:menu-2-fill" />
                </IconButton>
            )}

            <Container sx={{ display: "flex", flexDirection: "row" }}>
                <Box
                    onClick={() => navigate("/ceo/shopping-manager")}
                    sx={{ display: { xs: 'block', md: 'block' } }}>
                    <IconButton>
                        {/* @ts-ignore */}
                        <Iconify icon="tabler:home-cog" />
                    </IconButton>
                </Box>

                {/* <Box
                    onClick={() => navigate("/")}
                    sx={{ display: { xs: 'block', md: 'block' } }}>
                    <IconButton>
                        <Iconify icon="bxs:shopping-bags" />
                    </IconButton>
                </Box>

                <Box
                    onClick={() => navigate("/blog")}
                    sx={{ display: { xs: 'block', md: 'block' } }}>
                    <IconButton>
                        <Iconify icon="fa6-solid:blog" />
                    </IconButton>
                </Box>
                <Searchbar /> */}
            </Container>

            <div style={{ marginLeft: "auto" }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    {/* <LanguagePopover /> */}
                    {/* <NotificationsPopover menuControl={menuControl} /> */}
                    {/* //Ngăn hành động click vào các ô khác làm thay đổi trạng thái của giỏ hàng */}
                    <div onClick={(e) => e.stopPropagation()}>
                        {/*// @ts-ignore*/}
                        {menuControl && <CartPopover />}
                    </div>
                    <AccountPopover menuControl={menuControl} />
                </Stack>
            </div>
        </>
    );

    return (
        // Đổi AppBar thành div để không bị che bởi Nav
        <AppBar
            // @ts-ignore
            sx={{
                boxShadow: 'none',
                height: HEADER.H_MOBILE,
                zIndex: theme.zIndex.appBar + 1,
                ...bgBlur({
                    color: theme.palette.background.default,
                }),
                transition: theme.transitions.create(['height'], {
                    duration: theme.transitions.duration.shorter,
                }),
                ...(lgUp && {
                    width: `calc(100% - ${NAV.WIDTH + 1}px)`,
                    height: HEADER.H_DESKTOP,
                }),
            }}
        >
            <Toolbar
                sx={{
                    height: 1,
                    px: { lg: 5 },
                }}
            >
                {renderContent}
            </Toolbar>
        </AppBar>
    );
}

Header.propTypes = {
    onOpenNav: PropTypes.func,
};


export default Header;