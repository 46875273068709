import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeFromCart, editQuantity, toggleCartPopup } from '~redux/slices/shopping/cartSlice';
import { RootState } from '~redux/store';
import { useDetectClickOutside } from "react-detect-click-outside";
import { List, ListItem, ListItemText, ListItemAvatar, IconButton, Button, Box, Tooltip } from '@mui/material';
import Iconify from '~components/image/iconify/iconify.tsx';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';


//@ts-ignore
const CartPreview = ({ closeCart }) => {
    const ref = useDetectClickOutside({ onTriggered: closeCart });
    const dispatch = useDispatch();
    const cartItems = useSelector((state: RootState) => state.cart.items);

    const filteredCartItems = cartItems.filter(item => !item.isMuaSau);

    const cartTotal = cartItems.reduce((total, item) => {
        const donGia = item.donGia ?? 0;
        const soLuong = item.soLuong ?? 0;
        return total + donGia * soLuong;
    }, 0);

    const handleRemove = (maSanPham: number) => {
        //@ts-ignore
        dispatch(removeFromCart(maSanPham));
    };

    const handleQuantityChange = (maSanPham: any, soLuong: any) => {
        console.log(soLuong);
        console.log(maSanPham);
        if (soLuong >= 0) {
            // @ts-ignore
            dispatch(editQuantity({ maSanPham, soLuong }));
        }
    };


    const navigate = useNavigate();
    const handleProceedCheckout = () => {
        dispatch(toggleCartPopup());
        //@ts-ignore
        navigate('/shopping/cart', { state: { cartItems } });
    };


    //@ts-ignore
    const productImage = (item: CartItem) => {
        const url = item.anhMinhHoa ?? process.env.REACT_APP_DEFAULT_IMAGE;
        return url;
    }

    return (
        <div ref={ref} style={{ position: 'fixed', top: '50px', right: '10px', background: "#edf0f1", borderRadius: "15px", border: "solid #bfbfbf 1.5px" }}>
            <List sx={{ height: 420, overflowY: 'auto', width: 370, bgcolor: '#edf0f1', borderTopLeftRadius: "15px", borderTopRightRadius: "15px" }}>
                {filteredCartItems.map((item) => (
                    <ListItem key={item.maSanPham} style={{ padding: 0 }}>
                        <ListItemAvatar>
                            <img
                                style={{ width: 50, maxHeight: "65px", margin: "1px", borderRadius: 10, objectFit: 'cover', overflow: 'hidden' }}
                                src={productImage(item)}
                                alt={item.tenSanPham}
                            />
                        </ListItemAvatar>

                        <ListItemText
                            style={{
                                width: 320,
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                            primary={item.tenSanPham}
                            secondary={
                                <>
                                    <Tooltip title={item.note.slice(0, item.note.lastIndexOf(","))}>
                                        <span>
                                            <span>{item.note.slice(0, item.note.indexOf("toppings")).replace(/,\s*$/, "")}</span>
                                        </span>
                                    </Tooltip>
                                    <br />
                                    <span>
                                        {/* @ts-ignore */}
                                        Toppings: {item.note.match(/\[.*?\]/) ? item.note.match(/\[.*?\]/)[0].slice(1, -1) : ""}
                                    </span>
                                </>
                            }
                        />

                        <div style={{ width: 65, textAlign: 'right' }}>
                            {`${(item.donGia ?? 0).toLocaleString('vi-VN')}₫`}
                        </div>

                        <Box sx={{ display: 'flex', alignItems: 'center', width: 60 }}>
                            <select
                                value={item.soLuong}
                                onChange={(e) => handleQuantityChange(item.maSanPham, parseInt(e.target.value))}
                                style={{
                                    margin: "10px",
                                    color: 'black',
                                    backgroundColor: 'white',
                                    border: '1px solid #d9d9d9',
                                    borderRadius: '4px',
                                    height: '25px',
                                    textAlignLast: 'center',
                                }}
                            >
                                {[...Array(50).keys()].map((number) => (
                                    <option key={number + 1} value={number + 1}>
                                        {number + 1}
                                    </option>
                                ))}
                            </select>

                            <IconButton
                                style={{ margin: 0, padding: 0, marginLeft: "0px" }}
                                onClick={() => handleRemove(item.maSanPham)}>
                                {/* @ts-ignore */}
                                <Iconify icon="eva:close-outline" />
                            </IconButton>
                        </Box>

                    </ListItem>
                ))
                }
            </List >
            <Typography sx={{ textAlign: "right", fontWeight: 600, fontSize: '24px', m: 2 }}>
                Tổng: {cartTotal.toLocaleString('vi-VN')}đ
            </Typography>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Button fullWidth variant="contained" onClick={handleProceedCheckout} style={{ borderBottomLeftRadius: "15px", borderBottomRightRadius: "15px", borderTopLeftRadius: '0px', borderTopRightRadius: '0px', color: "#373A40" }}>
                    THANH TOÁN
                </Button>
            </div>
        </div >
    );
}

export default CartPreview;
